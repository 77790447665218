<template>
  <loading v-if="is_loading" />
  <div v-else class="profile">
    <b-row v-if="pageData">
      <!-- right-side -->
      <b-col sm="12">
        <b-card
          body-class="pb-0"
          :img-src="filterImages(pageData.media, 'cover_image')"
          :img-alt="pageData.first_name"
          img-top
        >
          <b-avatar
            class="my-avatar"
            size="10rem"
            :src="filterImages(pageData.media, 'path')"
            :img-alt="pageData.first_name"
          />
          <b-card-title class="font-weight-bold" style="font-size: 28px">
            {{ pageData.first_name }}
          </b-card-title>
          <b-row align-h="between">
            <b-col>
              <b-card-sub-title class="mt-0 mb-1 text-capitalize">
                {{ pageData.user_type }}
              </b-card-sub-title>
            </b-col>
            <b-col cols="auto">
              <b-link to="/edit-profile">
                <b-button :disabled="is_loading" variant="primary">
                  {{ $t("g.EditProfile") }}
                </b-button>
              </b-link>
            </b-col>
          </b-row>
          <hr />

          <b-col cols="12" nav-class="mb-0 wrap">
            <!-- Start Tab Header -->
            <div class="tab-header-scrollable">
              <b-tabs v-model="activeTab" style="overflow-x: auto">
                <b-tab
                  v-for="(tab, index) in tabs"
                  :key="index"
                  :title="tab.title"
                ></b-tab>
              </b-tabs>
            </div>
          </b-col>
          <!-- End Tab Header -->
        </b-card>

        <!-- Start Tab Body -->
        <b-card>
          <b-tabs v-model="activeTab" nav-class="d-none">
            <b-tab v-for="(tab, index) in tabs" :key="index">
              <div class="tab-body-title">
                <h4>
                  {{ tab.title }}
                </h4>
                <button
                  style="all: unset"
                  @click="isEdit = false"
                  v-if="isEdit && tab.title == 'About'"
                >
                  <img :src="editSvg" alt="" style="cursor: pointer" />
                </button>
                <button
                  style="all: unset"
                  @click="isEdit = true"
                  v-else-if="tab.title == 'About'"
                >
                  <img :src="closeSvg" alt="" style="cursor: pointer" />
                </button>
              </div>
              <hr />
              <profile-about-component
                v-if="tab.title == 'About'"
                :editable="true"
                :isEdit="isEdit"
                :aboutProps="componentsData.about"
              />
              <profile-info-component
                v-if="tab.title == 'Info'"
                :infoProps="componentsData.info"
              />
            </b-tab>
          </b-tabs>
        </b-card>
        <!-- End Tab Body -->
      </b-col>
      <!-- right-side -->
    </b-row>
  </div>
</template>

<script>
import helpers from "@/composables/helper";
import Loading from "@/components/loading/loading.vue";
import ProfileInfoComponent from "@/components/profile/ProfileInfoComponent";
import ProfileAboutComponent from "@/components/profile/ProfileAboutComponent";
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BCardSubTitle,
  BButton,
  BListGroupItem,
  BAvatar,
  BTabs,
  BTab,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BLink,
    BTabs,
    BButton,
    BAvatar,
    Loading,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroupItem,
    ProfileInfoComponent,
    ProfileAboutComponent,
  },
  data() {
    return {
      isError: false,
      is_loading: true,
      pageData: null,
      parentsData: null,
      friendsData: null,
      coursesData: null,
      activeTab: 0,
      isEdit: false,
      closeSvg: require("@/assets/images/icons/close.svg"),
      editSvg: require("@/assets/images/icons/edit.svg"),
      profileImgaePlaceholder: require("@/assets/images/placeholders/profileAvatar.png"),
      profileCoverPlaceholder: require("@/assets/images/placeholders/profileCover.png"),
      componentsData: {
        about: null,
        info: null,
        teaching: null,
      },
      tabs: [{ title: this.$t("g.About") }, { title: this.$t("g.Info") }],
    };
  },
  created() {
    this.getMyProfile();
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getMyProfile() {
      try {
        this.is_loading = true;
        const userRes = await this.$http.get("/auth/admin/profile");
        const data = userRes.data.data.user;
        this.pageData = data;
        this.componentsData = {
          ...this.componentsData,
          about: data.about,
          info: {
            email: data.email,
            name: data.first_name,
            phone: data.phone?.length ? data.phone[0].phone : null,
            country: data.country.name,
            birthday: data.birthdate,
          },
        };
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    convertDate(date) {
      // Convert the timestamp to a Date object
      const dateObject = new Date(date);

      // Desired output format
      const options = { year: "numeric", month: "short", day: "numeric" };

      // Convert the Date object to the desired output format
      const desiredOutput = dateObject.toLocaleDateString("en-US", options);

      return desiredOutput;
    },
    filterImages(data, name) {
      const placeholderImage =
        name === "path"
          ? helpers.uiAvatar(this.pageData?.first_name)
          : this.profileCoverPlaceholder;
      if (data) {
        for (let image of data) {
          if (image.name === name) {
            return image.path;
          }
        }
      } else {
        return placeholderImage;
      }
      return placeholderImage;
    },
  },
};
</script>

<style lang="scss">
@import "./profile.scss";
</style>
